import React from 'react';
import './Filter.scss';
import { IFilterProps } from './IFilterProps';
import IRefiner from '../../../Models/IRefiner';
import IFilterState from './IFilterState';
import { DisplayFormat } from '../../../Models/DisplayFormat';
import { SearchSource } from '../../LPVSok/getResults';
import { Log } from '../../../utils/logging';

export default class Filter extends React.Component<IFilterProps, IFilterState> {
    private collapsedFilterThreshold = 7;
    
    public constructor(props) {
        super(props);
        Log(props);
        const initialFilterKeys = this.setupInitialFilterKeys();
        const initialCollapsedFilters = this.setupInitialCollapsedFilters();
        this.state = {
            openFilterKeys: initialFilterKeys,
            collapsedFilters: initialCollapsedFilters,
            isMobileOpen: false,
            hiddenFilterNames: []
        };
    }

    private setupInitialCollapsedFilters(){
        let collapsedFilters: string[] = [];
        this.props.refiners.forEach(refiner => {
            collapsedFilters.push(refiner.Name);
        });
        return collapsedFilters;
    }

    private setupInitialFilterKeys() {
        if (this.props.displayFormat === DisplayFormat.Desktop) {
            let openFilterKeys: string[] = [];
            this.props.refiners.forEach(refiner => {
                const hasCheckedCheckbox = refiner.Entries.filter(entry => entry.checked).length > 0;
                if (hasCheckedCheckbox || this.props.isOpenOnPageLoad) {
                    openFilterKeys.push(refiner.Name);
                }
            });
            return openFilterKeys;
        }
        return [];
    }

    public render() {
        if (this.props.displayFormat === DisplayFormat.Desktop) {
            return this.renderDesktopVersion();
        } else {
            return this.renderMobileVersion();
        }
    }

    private renderDesktopVersion() {
        return (
            <div className="filterContainer">
                <div className="filterHeading"><h2>Filtrer søk</h2></div>
                {this.props.refiners.map((refiner, idx) => {
                    return this.renderFilterGroup(refiner.Name, refiner.Entries, idx, false);
                })}
                {this.renderSourceToggle(false)}
            </div>
        );
    }

    private renderMobileVersion() {
        if (!this.props.refiners || this.isEmpty(this.props.refiners)) return null;
        const isOpen = this.state.isMobileOpen;
        const isOpenClass = isOpen ? 'accordionPanelIsOpen' : '';
        const isOpenButtonClass = isOpen ? 'accordionIsOpen' : '';
        const isOpenChevronClass = isOpen ? 'accordionArrowActive' : '';
        return (
            <div className="filterContainer mobileFilterContainer">
                <div className="filterGroup">
                    {this.renderExpandableBox(isOpenButtonClass, isOpenChevronClass)}
                    <div className={`filterPanel ${isOpenClass} mobileFilterPanel`}>
                        {this.props.refiners.map((refiner, idx) => {
                            return this.renderFilterGroup(refiner.Name, refiner.Entries, idx, true);
                        })}
                        {this.renderSourceToggle(true)}
                    </div>
                </div>
            </div>
        );
    }

    private renderFilterGroup = (filterName: string, filters: IRefiner[], idx: number, isMobile: boolean) => {
        const isOpen = this.state.openFilterKeys.indexOf(filterName) > -1;
        const isOpenClass = isOpen ? 'accordionPanelIsOpen' : '';
        const isOpenButtonClass = isOpen ? 'accordionIsOpen' : '';
        const isOpenChevronClass = isOpen ? 'accordionArrowActive' : '';
        let isHiddenInUIClass = '';
        if(this.props.hiddenFilterNames && this.props.hiddenFilterNames?.indexOf(filterName)!==-1) { isHiddenInUIClass = 'is-hidden'; }
        return (
            <div className={`filterGroup ${isMobile ? 'mobileFilterGroup' : 'desktopFilterGroup'} ${isHiddenInUIClass}`} key={`filterGroup-${idx}`}>
                {this.renderExpandableBox(isOpenButtonClass, isOpenChevronClass, filterName)}
                <div className={`filterPanel ${isOpenClass}`}>
                    {this.renderCheckBoxes(filters, filterName)}
                    {this.displayShowMoreButton(filters, filterName)}
                </div>
            </div>
        );
    };

    private renderExpandableBox(isOpenButtonClass: string, isOpenChevronClass: string, filterName?: string) {
        const isOpen = isOpenButtonClass.length > 0 ? 'true' : 'false';
        return (
            <button
                aria-expanded={isOpen}
                onMouseDown={this.preventFocus}
                className={`filterGroupAccordion ${isOpenButtonClass}`}
                onClick={filterName ? () => this.toggleAccordion(filterName) : () => this.toggleMobileAccordion()}
            >
                <div className="accordionFilterDisplayName">{filterName ? this.props.getFilterDisplayName(filterName) : 'Filtrer søk'}</div>
                <span className="accordionIcon">
                    <span className={`accordionArrow ${isOpenChevronClass}`}>
                        <svg className="icon" focusable="false" width="20" height="20" viewBox="0 0 20 20">
                            <g strokeWidth="1.5">
                                <circle cx="10" cy="10" r="9"></circle>
                                <path fill="none" d="M5 9l5 4 5-4"></path>
                            </g>
                        </svg>
                    </span>
                </span>
            </button>
        );
    }

    private toggleMobileAccordion() {
        const isOpen = !this.state.isMobileOpen;
        this.setState({
            isMobileOpen: isOpen
        });
    }

    private toggleShowMore(filters: IRefiner[], filterName: string){
        let collapsedFilt = this.state.collapsedFilters.slice();
        if (collapsedFilt.indexOf(filterName) > -1) {
            collapsedFilt = collapsedFilt.filter(key => key !== filterName);
        } else {
            collapsedFilt.push(filterName);
        }
        this.setState({
            collapsedFilters: collapsedFilt
        });
    }

    private displayShowMoreButton = (filters: IRefiner[], filterName: string) => {
        let showMoreLessText = this.state.collapsedFilters.indexOf(filterName) > -1 ? "Vis mer" : "Vis mindre";
        const showMoreClass = this.state.collapsedFilters.indexOf(filterName) > -1 ? "more" : "less";
        //Log(filters.length);
        //Log(this.collapsedFilterThreshold);
        //Log("------------------------" + filterName);
        if(filters.length > this.collapsedFilterThreshold+1){
            return(
                <button className={`accordion-button btn ${showMoreClass}`}
                    onClick={filterName ? () => this.toggleShowMore(filters, filterName) : () => this.toggleShowMore(filters,filterName)}
                >
                    <span className={`accordion-open`}>{showMoreLessText}</span>
                    
                </button>
            );
        }
    }

    private renderCheckBoxes = (filters: IRefiner[], filterName: string) => {
        let checkBoxes = filters.map((filter, idx) => {
            const isIndentedClass: string = filter.indentation ? 'checkboxIndetation' : '';
            const isChecked = filter.checked !== undefined && filter.checked === true;
            const count = filter.RefinementCount !== undefined ? `(${filter.RefinementCount})` : '';
            const isHiddenClass = (filters.length >= this.collapsedFilterThreshold && idx > this.collapsedFilterThreshold && this.state.collapsedFilters.indexOf(filterName) > -1) ? 'is-hidden' : '';
            return (
                <div className={`checkBox ${isIndentedClass} ${isHiddenClass}`} key={`checkbox-${idx}`}>
                    <input
                        id={`checkBoxInput-${filterName}-${idx}`}
                        className='checkbox__input'
                        type="checkBox"
                        onChange={e => {
                            this.onCheckBoxmarked(filterName, idx);
                        }}
                        checked={isChecked}
                        onKeyDown={e => this.onKeyPressed(e, filterName, idx)}
                        />
                    <label
                        htmlFor={`checkBoxInput-${filterName}-${idx}`}
                        onKeyDown={e => this.onKeyPressed(e, filterName, idx)}
                        onMouseDown={e => e.preventDefault()}
                        //onClick={() => this.onCheckBoxmarked(filterName, idx)}
                        className="checkboxLabel checkbox__label"
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" className="icon icon--filled checkbox__checkmark" focusable="false" aria-hidden="true" role="checkbox">
                            <path d="M5.79 11 1 6.33l1.4-1.41 3.17 3.09L10.9 1l1.6 1.19L5.79 11z" style={{ fill: '#3d3d3d' }}></path>
                        </svg>
                        <span className="checkboxLabel-text">{`${filter.RefinementName} ${count}`}</span>
                    </label>
                </div>
            );
        });

        checkBoxes.unshift(
            <div className={`checkBox`} key={`checkbox-${'-1'}`}>
                <input
                    id={`checkBoxInput-${filterName}-${'-1'}`}
                    type="checkBox"
                    onChange={() => {
                        this.onAllFiltersClicked(filterName);
                    }}
                    checked={filters.filter(filter => filter.checked).length === 0}
                    onKeyDown={e => this.onAllKeyPressed(e, filterName)}
                    />
                <label
                    htmlFor={`checkBoxInput-${filterName}-${'-1'}`}
                    onClick={() => this.onAllFiltersClicked(filterName)}
                    onKeyDown={e => this.onKeyPressed(e, filterName, -1)}
                    onMouseDown={e => e.preventDefault()}
                >
                    <div />
                    <span>Alle - {`${this.props.getFilterDisplayName(filterName)}`}</span>
                </label>
            </div>
        );
        return checkBoxes;
    };

    private preventFocus(e) {
        e.preventDefault();
    }
    private renderSourceToggle(isMobile: boolean) {
        if (this.props.source === undefined) {
            Log("this.props.source is undefined. Will not render Inkluder utgåtte versjoner");
            return;
        }
        return (
            <div className={`filterGroup ${isMobile ? 'mobileFilterGroup' : 'desktopFilterGroup'}`} key={`filterGroup-999`}>
                <div className="filterGroupAccordion switchSourceButton">
                    <div className={`checkBox no-margin-left`} key={`checkbox-${'-1'}`}>
                        <input id={`checkBoxInput-include-utgatt`} 
                                name='checkBoxInput-include-utgatt'
                                value='checkBoxInput-include-utgatt'
                                type="checkbox" 
                                //disabled 
                                onChange={() => this.onSourceButtonPressed()}
                                onKeyDown={e => this.onUtgaattButtonPressed(e)}
                                checked={this.props.source === SearchSource.All} />
                                
                        <label htmlFor={`checkBoxInput-include-utgatt`} 
                                onMouseDown={e => e.preventDefault()} 
                                //onClick={() => this.onSourceButtonPressed()}
                                >
                            <div className="sourceToggleCheckbox" />
                            <span>Inkluder utgåtte versjoner</span>
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    private toggleAccordion(accordionName: string) {
        let newOpenFilterKeys = this.state.openFilterKeys.slice();
        if (newOpenFilterKeys.indexOf(accordionName) > -1) {
            newOpenFilterKeys = newOpenFilterKeys.filter(key => key !== accordionName);
        } else {
            newOpenFilterKeys.push(accordionName);
        }
        this.setState({
            openFilterKeys: newOpenFilterKeys
        });
    }

    private onCheckBoxmarked(refinerName: string, refinerIndex: number) {
        this.props.onFilterSelected(refinerName, refinerIndex);
    }

    private isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    private onKeyPressed(event: React.KeyboardEvent, refinerName: string, refinerIndex: number) {
        if (event.key === 'Enter') {
            this.onCheckBoxmarked(refinerName, refinerIndex);
        }
    }
    private onAllFiltersClicked(filterName: string) {
        this.props.onAllFilterClicked(filterName);
    }
    private onAllKeyPressed(event: React.KeyboardEvent, refinerName) {
        if (event.key === 'Enter') {
            this.props.onAllFilterClicked(refinerName);
        }
    }

    private onSourceButtonPressed() {
        if (!this.props.onSourceChanged) return;
        let newSource = this.props.source === SearchSource.Default ? SearchSource.All : SearchSource.Default;
        this.props.onSourceChanged(newSource);
    }
    
    private onUtgaattButtonPressed(event: React.KeyboardEvent) {
        if (!this.props.onSourceChanged) return;
        if (event.key === 'Enter') {
            let newSource = this.props.source === SearchSource.Default ? SearchSource.All : SearchSource.Default;
            this.props.onSourceChanged(newSource);
        }
    }
}
