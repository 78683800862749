import React, { FunctionComponent } from 'react';
import './MoveToTop.scss';


export const MoveToTop: FunctionComponent<{}> = () => {
    return (
        <div id="toTopContainer" className="toTopContainer hidden">
            <button className="toTopLink" onClick={(e) => topFunction()}>
                <svg className="toTopIcon" focusable="false" width="23" height="13" viewBox="0 0 23 13"><g fill="none"><path d="M22.5 5.5l-8 5.5V0zM14 5.5H0"></path></g></svg>
                <div className="text">Til toppen</div>
            </button>
        </div>
    )
}


export function topFunction() {
    const headerElement = document.getElementById("go-to-top-target");

    if (headerElement) {
        headerElement.focus();
    } else {
        console.warn("Element with ID 'go-to-top-target' not found");
    }
    //window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: "smooth" });
}

export function toggleToTopComponent(hasResults?: boolean) {
    var element = document.getElementById("toTopContainer");
    var header = document.getElementById("header");
    var root = document.getElementById("root");
    
    if (isOverflown(header, root) && hasResults) {
        if (element != null) {
            element.classList.remove("hidden");
        }
    } else {
        if (element != null ) {
            element.classList.add("hidden");
        }
    }
}

export function isOverflown(element, element2?) {
    var scrollHeight = element.scrollHeight;
    if(element2){
        scrollHeight += element2.scrollHeight;
    }
    return scrollHeight > window.innerHeight;
}
